export default [
  {
    path: ''
  },
  {
    path: 'login',
    name: 'BoLogin',
    component: () => import('@/views/BoLogin/IndexView.vue'),
    meta: {
      redirectIfLoggedIn: true,
      layout: 'empty'
    }
  },

  {
    path: 'dashboard',
    name: 'BoDashboard',
    component: () => import('@/views/BoDashboard.vue'),
    meta: {
      generalMenu: true,
      menu: 'BoDashboard',
      pageTitle: 'Dashboard',
      objName: 'Dashboard'
    }
  },
  {
    path: 'partner-registration/:pageSlug?/:pageId?',
    name: 'BoPartnerRegistration',
    component: () => import('@/views/BoPartnerRegistration/IndexView.vue'),
    meta: {
      menu: 'BoPartnerRegistration',
      pageTitle: 'Partner',
      objName: 'Partner Registration'
    }
  },
  // {
  //   path: 'notification',
  //   name: 'BoNotification',
  //   component: () => import('@/views/BoNotification.vue'),
  //   meta: {
  //     generalMenu: true,
  //     menu: 'BoNotification',
  //     pageTitle: 'Notification',
  //     objName: 'Notification'
  //   }
  // },

  // {
  //   path: 'setting/audit-trail',
  //   name: 'BoLogAuditTrail',
  //   component: () => import('@/views/BoLogAuditTrail/IndexView.vue'),
  //   meta: {
  //     menu: 'BoLogAuditTrail',
  //     pageTitle: 'Audit Trail',
  //     objName: 'Audit Trail'
  //   }
  // },
  // {
  //   path: 'setting/app-config',
  //   name: 'BoAppConfig',
  //   component: () => import('@/views/BoAppConfig/IndexView.vue'),
  //   meta: {
  //     menu: 'BoAppConfig',
  //     pageTitle: 'Konfigurasi App',
  //     objName: 'Konfigurasi'
  //   }
  // },
  {
    path: 'setting/user/:pageSlug?/:pageId?',
    name: 'BoUser',
    component: () => import('@/views/BoUser/IndexView.vue'),
    meta: {
      menu: 'BoUser',
      pageTitle: 'User List',
      objName: 'User'
    }
  },
  {
    path: 'setting/user-level/:pageSlug?/:pageId?',
    name: 'BoUserLevel',
    component: () => import('@/views/BoUserLevel/IndexView.vue'),
    meta: {
      menu: 'BoUserLevel',
      pageTitle: 'Access Level',
      objName: 'Access Level'
    }
  },
  {
    path: 'product-management/product-category/:pageSlug?/:pageId?',
    name: 'BoProductCategory',
    component: () => import('@/views/BoProductCategory/IndexView.vue'),
    meta: {
      menu: 'BoProductCategory',
      pageTitle: 'Product Category',
      objName: 'Product Category'
    }
  },
  {
    path: 'product-management/bank/:pageSlug?/:pageId?',
    name: 'BoBank',
    component: () => import('@/views/BoBank/IndexView.vue'),
    meta: {
      menu: 'BoBank',
      pageTitle: 'Bank',
      objName: 'Bank'
    }
  },
  {
    path: 'product-management/klasifikasi-usaha/:pageSlug?/:pageId?',
    name: 'BoKlasifikasiUsaha',
    component: () => import('@/views/BoKlasifikasiUsaha/IndexView.vue'),
    meta: {
      menu: 'BoKlasifikasiUsaha',
      pageTitle: 'Klasifikasi Usaha',
      objName: 'Klasifikasi Usaha'
    }
  },
  {
    path: 'product-management/area/:pageSlug?/:pageId?',
    name: 'BoArea',
    component: () => import('@/views/BoArea/IndexView.vue'),
    meta: {
      menu: 'BoArea',
      pageTitle: 'Area',
      objName: 'Area'
    }
  },
  {
    path: 'product-management/operator/:pageSlug?/:pageId?',
    name: 'BoOperator',
    component: () => import('@/views/BoOperator/IndexView.vue'),
    meta: {
      menu: 'BoOperator',
      pageTitle: 'Operator',
      objName: 'Operator'
    }
  },
  {
    path: 'product-management/operator-prefix/:pageSlug?/:pageId?',
    name: 'BoOperatorPrefix',
    component: () => import('@/views/BoOperatorPrefix/IndexView.vue'),
    meta: {
      menu: 'BoOperatorPrefix',
      pageTitle: 'Operator Prefix',
      objName: 'Operator Prefix'
    }
  },
  {
    path: 'supplier-management/supplier/:pageSlug?/:pageId?',
    name: 'BoSupplier',
    component: () => import('@/views/BoSupplier/IndexView.vue'),
    meta: {
      menu: 'BoSupplier',
      pageTitle: 'Supplier',
      objName: 'Supplier'
    }
  },
  {
    path: 'master/template/:pageSlug?/:pageId?',
    name: 'BoTemplate',
    component: () => import('@/views/BoTemplate/IndexView.vue'),
    meta: {
      menu: 'BoTemplate',
      pageTitle: 'Template',
      objName: 'Template'
    }
  },
  {
    path: 'product-list/edms-product/:pageSlug?/:pageId?',
    name: 'BoEdmsProduct',
    component: () => import('@/views/BoEdmsProduct/IndexView.vue'),
    meta: {
      menu: 'BoEdmsProduct',
      pageTitle: 'EDMS Product',
      objName: 'EDMS Product'
    }
  }
]
