import store from '@/store'
import _ from '@/libs/lodash.js'
import axios from 'axios'
import Wording from '@/@config/wording.json'
import Master from '@/@config/master.json'

const CryptoJS = require('crypto-js')

const Gen = {
  apiHeader () {
    return {
      // 'X-API-KEY': 'Basic ' + process.env.VUE_APP_API_KEY,
      // 'X-Frame-Options': 'sameorigin',
      // 'X-XSS-Protection': '1; mode=block',
      'X-CODE-CHALLENGE': store.getters.BoUserToken,
      Authorization: `Bearer ${store.getters.BoUserToken}`
      // 'X-Content-Type-Option': 'nosniff',
      // 'X-CSRF-TOKEN': document.querySelector("meta[name='csrf-token']").content,
      // 'Content-Security-Policy': 'script-src \'self\'',
      // 'X-SESS-PAGE-ID': window.sessPageID,
      // 'X-SESS-PAGE-COOKIE': document.querySelector('meta[name=\'sess-cookie\']').content,
      // 'Strict-Transport-Security': 'max-age=31536000;includeSubDomains'
    }
  },
  uploadRest (path, formData) {
    return axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      headers: { ...Gen.apiHeader() },
      method: 'POST',
      withCredentials: false
    }).post(
      path,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  },
  apiRest (path, config = {}) {
    window.isHoldRefreshUA = 'Y'
    const options = {
      baseURL: process.env.VUE_APP_API_URL,
      headers: { ...Gen.apiHeader() },
      method: (config.method || 'GET').toUpperCase(),
      url: path
      // withCredentials: true
    }
    let data = {}
    let params = {}
    let headers = {}

    if (config.data) {
      options.method = config.method ? config.method : 'POST'
      data = _.assign(data, config.data)
      // data = {
      //   payload: Gen.CryptoJS_Aes_Encrypt(process.env.VUE_APP_DATA_KEY, JSON.stringify(data))
      // }
    }
    if (config.params) params = _.assign(params, config.params)
    if (config.headers) headers = _.assign(headers, config.headers)

    if (_.keys(data).length) options.data = data
    if (_.keys(params).length) options.params = params
    if (_.keys(headers).length) options.headers = headers

    if (path.indexOf('export_list') > -1 || params.export === 'Y') {
      options.responseType = 'blob'
      options.headers = { ...options.headers, 'Content-Type': 'multipart/form-data' }
    }

    // return axios(options)
    return new Promise((resolve, reject) => {
      axios(options)
        .then(resp => {
          window.isHoldRefreshUA = false
          if (options.method === 'POST' && resp.data.csrfToken) {
            document.querySelector('meta[name="csrf-token"]').setAttribute('content', resp.data.csrfToken)
          }
          resolve(resp)
        })
        .catch(err => {
          window.isHoldRefreshUA = false
          if (options.method === 'POST' && err.response.data.csrfToken) {
            document.querySelector('meta[name="csrf-token"]').setAttribute('content', err.response.data.csrfToken)
          }
          reject(err)
        })
    })
  },
  
  CryptoJS_Aes_Encrypt (pass, value) {
    const salt = CryptoJS.lib.WordArray.random(256)
    const iv = CryptoJS.lib.WordArray.random(16)
    const key = CryptoJS.PBKDF2(pass, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 999 })
    const encrypted = CryptoJS.AES.encrypt(value, key, { iv: iv })
    const data = {
      ciphertext: CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
      salt: CryptoJS.enc.Hex.stringify(salt),
      iv: CryptoJS.enc.Hex.stringify(iv)
    }
    return JSON.stringify(data)
  },

  putStorage (key, value) {
    if (!value) return
    if (
      value.constructor === [].constructor ||
      value.constructor === ({}).constructor
    ) {
      localStorage.setItem(key, JSON.stringify(value))
    } else {
      localStorage.setItem(key, value)
    }
  },
  getStorage (key, def = '') {
    const item = localStorage.getItem(key)
    if (!item) return def
    try {
      return JSON.parse(item)
    } catch (e) {
      return item
    }
  },
  removeStorage (key) {
    localStorage.removeItem(key)
  },

  getCookie (name) {
    const nameEQ = name + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  },
  setCookie (name, value, num = 1, type = 'day') {
    try {
      let expires = ''
      let cookieDomain = ''

      if (location.hostname !== 'localhost') {
        const a = document.createElement('a')
        a.href = process.env.VUE_APP_BASE_URL
        cookieDomain = a.hostname
      }

      const date = new Date()
      if (type === 'day') {
        date.setTime(date.getTime() + (num * 24 * 60 * 60 * 1000))
        expires = '; expires=' + date.toUTCString()
      } else if (type === 'minute') {
        date.setTime(date.getTime() + (num * 60 * 1000))
        expires = '; expires=' + date.toUTCString()
      }

      document.cookie = name + '=' + (value || '') + expires + '; path=/; domain=' + cookieDomain
    } catch (err) {
      console.log(err)
    }
  },

  moduleRole (route, roles, currentMenu = route.name) {
    currentMenu = currentMenu || route.name
    roles = roles.constructor === ([]).constructor ? roles : [roles]
    return !!_.find(store.getters.BoUser.menu_access || [], val => {
      if (val.menu === currentMenu) {
        return _.intersection(roles, val.module || []).length >= 1
      } else {
        return false
      }
    })
  },

  loadingGlobal () {
    window.Swal.fire({
      html: '<div class="sk-wave sk-white" style="margin: auto; width: 100px;"> <div class="sk-wave-rect"></div> <div class="sk-wave-rect"></div> <div class="sk-wave-rect"></div> <div class="sk-wave-rect"></div> <div class="sk-wave-rect"></div> </div>',
      background: 'transparent',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false
    })
  },

  layoutFullScreen () {
    if (
      document.querySelectorAll('.layout-menu-toggle').length && 
      document.querySelector('html').classList.contains('layout-menu-collapsed') === false
    ) {
      document.querySelectorAll('.layout-menu-toggle')[0].click()
    }
  },

  strVar (wording, params) {
    let wordingTxt = wording

    _.forEach(Object.keys(params), param => {
      wordingTxt = wordingTxt.replace(
        new RegExp('{' + param + '}', 'g'),
        params[param]
      )

      if (param.indexOf('{') > -1) {
        wordingTxt = wordingTxt.replace(
          new RegExp(param, 'g'),
          params[param]
        )
      }
    })

    return wordingTxt
  }
}

export { Gen, Wording, Master }
