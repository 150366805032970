import _ from 'lodash'
import { createRouter, createWebHistory } from 'vue-router'
import boRoutes from './bo_routes'
import store from '@/store'

const routes = [
  {
    path: '/',
    component: () => import('@/@layout/BaseMain.vue'),
    children: boRoutes
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// initiate validate route
const user = JSON.parse(localStorage.getItem('BoUser') || '{}')

const menuActive = activeMenu => {
  activeMenu = activeMenu.constructor === ([]).constructor ? activeMenu : [activeMenu]
  const isExist = !!_.find(user.menu_access || [], v => { return activeMenu.indexOf(v.menu) > -1 })
  return isExist
}

// validate routes
router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters.authenticated

  if (!to.name) {
    if (new URLSearchParams(location.search).get('uToken') && process.env.VUE_APP_MODE === 'local') {
      next()
    } else if (isLoggedIn) {
      next({ name: 'BoDashboard' })
    } else {
      localStorage.setItem('newLogin', 'Y')
      window.location.href = process.env.VUE_APP_SSO_URL
    }
  } else if (!isLoggedIn && !to.meta.redirectIfLoggedIn) {
    localStorage.setItem('newLogin', 'Y')
    window.location.href = process.env.VUE_APP_SSO_URL
  } else if (isLoggedIn && to.meta.redirectIfLoggedIn) {
    next({ name: 'BoDashboard' })
  } else if (isLoggedIn) {
    to.name = to.name || ''
    if (menuActive(to.name) || to.meta.generalMenu) {
      next()
    } else {
      next({ name: 'BoDashboard' })
    }
  } else {
    next()
  }
})

export default router
