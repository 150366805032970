import _ from 'lodash'

_.mixin({
  genClone: (value) => {
    if (!value) return value
    else if (
      value.constructor === ([]).constructor ||
      value.constructor === ({}).constructor
    ) {
      return JSON.parse(JSON.stringify(value))
    } else {
      return value
    }
  }
})

export default _
