import { createStore } from 'vuex'
import createMultiTabState from 'vuex-multi-tab-state'
import { Gen } from '@/libs/Gen.js'
import moment from 'moment'
import _ from 'lodash'

export default createStore({
  plugins: [
    createMultiTabState({
      statesPaths: [
        'BoUserToken', 
        'BoUser', 
        'badgeNotif', 
        'listDownload'
      ]
    })
  ],
  state: {
    meta: { title: process.env.VUE_APP_TITLE },
    BoUserToken: null,
    BoUser: {},
    loadingGlobal: false,
    data: {},
    badgeNotif: 0,
    listDownload: [],
    uploader: {}
  },
  getters: {
    BoUserToken: state => state.BoUserToken,
    BoUser: state => state.BoUser || {},
    authenticated: state => state.BoUserToken && state.BoUser?.username
  },
  mutations: {
    updateUser (state, value = null) {
      value = value || '{"level":{}}'
      state.BoUser = value
      if (value) Gen.putStorage('BoUser', value)
      else Gen.removeStorage('BoUser')
    },
    updateUserToken (state, value = null) {
      state.BoUserToken = value
      if (value) Gen.putStorage('BoUserToken', value)
      else Gen.removeStorage('BoUserToken')
    },
    logoutUserToken (state) {
      if (window.refreshUA) clearInterval(window.refreshUA)
      state.BoUserToken = null
      state.BoUser = {}
      Gen.removeStorage('BoUser')
      Gen.removeStorage('BoUserToken')
      localStorage.setItem('newLogin', 'Y')

      // const token = state.BoUserToken
      // window.location.href = process.env.VUE_APP_LOGOUT_URL + '?token=' + token
      window.location.href = process.env.VUE_APP_LOGOUT_URL
    },
    turnOnLoading (state) {
      state.loadingGlobal = true
      document.body.style.overflow = 'hidden'
    },
    turnOffLoading (state) {
      state.loadingGlobal = false
      document.body.style.overflow = 'unset'
    },
    updateBadgeNotif (state, value = 0) {
      state.badgeNotif = value
    }
  },
  actions: {
    async watchAuth ({ commit }) {
      initTab()

      if (new URLSearchParams(location.search).get('uToken') && process.env.VUE_APP_MODE === 'local') {
        const response = await fetch(
          process.env.VUE_APP_API_URL + '/local-only?uToken=' + new URLSearchParams(location.search).get('uToken'), 
          {
            method: 'GET',
            credentials: 'include',
            headers: { 'X-API-KEY': 'Basic ' + process.env.VUE_APP_API_KEY }
          }
        )
        localStorage.setItem('newLogin', 'Y')
        Gen.putStorage('BoUserToken', await response.text())
        window.location.href = process.env.VUE_APP_BASE_URL
      } else {
        const userToken = Gen.getStorage('BoUserToken')
        commit('updateUserToken', userToken)
        commit('updateUser', null)

        if (userToken) {
          try {
            let resp = await Gen.apiRest('/v1/auth/get/info')
            resp = resp.data
            if (resp.status === 'success') {
              commit('updateUser', resp.data)
              commit('updateBadgeNotif', resp.badgeNotif)

              // refresh token auth
              window.refreshUA = () => {
                window.isRefresh = 'UA'
                setTimeout(() => {
                  fetch(
                    process.env.VUE_APP_API_URL + '/v1/auth/refresh-token',
                    {
                      method: 'GET',
                      // credentials: 'include',
                      headers: Gen.apiHeader()
                    }
                  )
                    .then(response => response)
                    .then(async response => {
                      if (response.status === 200) {
                        const resp = await response.json()
                        commit('updateUserToken', resp.codeChallenge)
                        commit('updateUser', resp.authInfo)
                      } else {
                        commit('logoutUserToken')
                      }
                      window.isRefresh = false
                    })
                    .catch(() => {})
                }, window.isHoldRefreshUA === 'Y' ? 10000 : 0)
              }

              // 15 minutes
              setInterval(() => {
                // if (window.firstTab() === window.currentTabID) {
                window.refreshUA()
                // }
              }, 15 * 60 * 1000)
            } else {
              commit('logoutUserToken')
            }
          } catch (error) {
            commit('logoutUserToken')
          }
        }
      }
    }
  },
  modules: {
  }
})

const initTabCount = () => {
  const nowTime = moment().format('YYYY-MM-DD HH:mm:ss')
  let tabs = JSON.parse(localStorage.getItem('Tabs') || '[]')
  if (_.find(tabs, v => v.tab === window.currentTabID)) {
    const index = _.map(tabs, 'tab').indexOf(window.currentTabID)
    tabs[index].updated = nowTime
  } else {
    tabs.push({
      tab: window.currentTabID,
      updated: nowTime
    })
  }
  tabs = _.filter(tabs, v => {
    return Math.abs(moment(v.updated).diff(nowTime, 'seconds')) <= 5
  })
  localStorage.setItem('Tabs', JSON.stringify(tabs))
}

const initTab = () => {
  window.currentTabID = moment().valueOf()
  setInterval(() => {
    initTabCount()
  }, 5 * 1000) // 5s
}

window.firstTab = () => {
  const tabs = JSON.parse(localStorage.getItem('Tabs') || '[]')
  return tabs[0].tab || ''
}
